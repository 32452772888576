import React from "react"
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import TourMenu from '../../components/TourMenu';
import { Helmet } from "react-helmet"
import {Link} from 'gatsby';

export default function ProgrammingTest() {

    return (

        <>

        <Helmet>
            <meta charSet="utf-8" />
            <title>Feature tour - get started | Skillmeter</title>
        </Helmet>
                
        <Header />

        <section className="py-12 bg-gray-50 sm:py-16 lg:py-20">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl ">
              Feature tour
            </h2>
            <p className="mt-6 text-lg font-normal text-gray-600 ">
            See how easy is to get started with Skillmeter
          </p>               
          </div>
  
          <div className="max-w-5xl mx-auto mt-10 sm:mt-20">
            <div className="flow-root">
            <div className="-my-8 divide-y divide-gray-200">
                <div className="py-8">
                  <div className="flex">
                    <div className="w-1/4">
                        <TourMenu option="3" />

                    </div>
                    <div className="w-3/4">
                      <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl ">
                      Programming test
                      </h2>               
                      <br /><br />       
                      Skillmeter provides the best platform to measure programming ability of your candidates.
                      <br /><br />       

                      <b>Code challenge</b>
                      <br />
                      You can create any type of programming test/challenge (usually algorithms problems to verify the practical knowledge) and Skillmeter will let candidates solve the test in the web-browser thanks to the programming editor we provide. In any moment the candidates can execute the code to verify it before they submit the solution.
                      <br /><br />       

                      Below you can see an example of how it looks to the candidates.
                      <br />
                      <img className="w-full max-w-md" src="https://d2mwjjtum67wgo.cloudfront.net/Content/Public3/img/tour/programming-test.png" alt="" />
                      
                      <br />       

                      <b>Supported languages</b>
                      <br />       
                      Programming tests can run in C++, C#, Java, Perl, Python, PHP, Ruby or VB.NET. To understand more about the environment, time limits, etc. you can read the <Link to='/codefaq'>FAQ here</Link>


                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </section>
  
        <Footer />
      </>
    )
}